//
// product.js
//
import { getFirestore, serverTimestamp, collection, query, where, doc, getDoc, getDocs, addDoc } from "firebase/firestore";

function makeId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


var submit = document.getElementById("join-submit");
if (submit) {
    submit.onclick = async function (event) {
        var form = document.querySelectorAll('.needs-validation')[0];
        if (!form.checkValidity()) {
            form.classList.add('was-validated')
            return
        }

        var email = document.getElementById("email").value.trim();
        var db = getFirestore();

        try {
            var docs = await getDocs(query(collection(db, "join"), where("email", "==", email)));
            if (docs.empty) {
                var d = await getDoc(doc(collection(db, "joinCount"), "number"));
                var number = d.data().number;
                var ref = makeId(6);

                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const refBy = urlParams.get("ref");

                await addDoc(collection(db, "join"), {
                    email: email, created: serverTimestamp(),
                    number: number, ref: ref, refBy: refBy,
                });
            }
        } catch (e) {
            console.error(e);
            return;
        }

        window.location.href = "/join?email=" + email;
    };
}