//
// join.js
// Join module
//
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore"


const text = "Join Chooly app with me. "
const url = "chooly.app?ref="
var joined = false;
var number = 800;
var ref = "";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const email = urlParams.get("email");

async function checkJoined() {
  if (email) {
    var db = getFirestore();
    try {
      var docs = await getDocs(query(collection(db, "join"), where("email", "==", email)));
      if (docs.empty) {
        return;
      }
      joined = true;
      var data = docs.docs[0].data();
      number = data.number;
      ref = data.ref;
    } catch (e) {
      console.error(e);
      return;
    }
  }
};

function link() {
  return `https://chooly.app?ref=${ref}`;
}

function twitterLink() {
  var url = link();
  return `http://twitter.com/intent/tweet/?text=${text}&url=${url}&via=AppChooly`;
}

function facebookLink() {
  var url = link();
  return `http://www.facebook.com/sharer/sharer.php?p[url]=${url}&p[title]=${text}`
}

var joinNumber = document.getElementById("join-number");
if (joinNumber) {
  window.onload = async () => {
    await checkJoined();
    joinNumber.textContent = "#" + number;
    document.getElementById("twitter-link").href = twitterLink();
    document.getElementById("facebook-link").href = facebookLink();
  };
}

const join_copies = document.querySelectorAll(".join-copy");
join_copies.forEach(join_copy => {
  join_copy.addEventListener("click", function () {
    navigator.clipboard.writeText(text + link()).then(function () {
      if (join_copy.children.length < 2) {
        var el = document.createElement("span")
        el.classList = "text-gray-600";
        el.innerHTML = "(copied to clipboard)";
        el.attributes["data-oas"] = "fade-in";
        join_copy.appendChild(el);
        window.setTimeout(() => { join_copy.removeChild(el) }, 700);
      }
    }, function () {
    });
    return false;
  });
});


