// 
// user.js
// Use this to write your custom JS
//
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBqQZgIbyPv5cK1-SyncT76jlxv53jVd_U",
    authDomain: "chooly-app.firebaseapp.com",
    projectId: "chooly-app",
    storageBucket: "chooly-app.appspot.com",
    messagingSenderId: "224113950923",
    appId: "1:224113950923:web:5cfb02d227ec78b1e25b4e",
    measurementId: "G-4DP5F000HM"
};

initializeApp(firebaseConfig);

if (location.hostname !== "localhost") {
    const analytics = getAnalytics();
}

var db = getFirestore();
if (location.hostname === "localhost") {
    connectFirestoreEmulator(db, "localhost", 5002);
}